import React from 'react';
import BackgroundImage from 'Components/BackgroundImage';
import { sanitizeLineBreaks } from 'utils/Html';
import s from './Hero.module.scss';

const Hero = ({ title, text, image = {}, icon }) => {
    const sanitizedTitle = sanitizeLineBreaks(title);
    return (
        <section className={s['Hero']}>
            <BackgroundImage
                image={image}
                className={s['Hero__Image']}
            />
            <div className={s['Hero__Content']}>
                {icon && icon.src && (
                    <div
                        className={s['Hero__Icon']}
                        style={{ backgroundImage: `url('${icon.src}')` }}
                    />
                )}
                {title &&
                    <h1
                        className={s['Hero__Title']}
                        dangerouslySetInnerHTML={{ __html: sanitizedTitle }}
                    />
                }
                {text && <p className={s['Hero__Preamble']}>{text}</p>}
            </div>
        </section>
    );
};

export default Hero;
